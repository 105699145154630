@import "../../assets/scss/mixins.scss";

.exercises-preview {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  height: 55vh;
  padding-left: 8%;

  @include breakpoint {
    padding: 0;
  }

  .exercises-list {
    overflow-y: scroll;
    padding-right: 20px;
    width: 20vw;

    @include breakpoint {
      display: none;
    }
  }

  .image-or-next-container {
    width: 55vw;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include breakpoint {
      width: 100%;
    }

    .image-or-next {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .get-ready {
        color: white;
        text-align: center;
      }
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--ramonak-color);
    border-radius: 10px;
  }
}
