@import "../../assets/scss/mixins.scss";

.option-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 10vh;
  margin: 1vw;
  border: 1.5px solid var(--ramonak-color);
  border-radius: 10px;
  color: var(--ramonak-color);
  cursor: pointer;

  @include breakpoint {
    width: 80%;
  }

  &.pressed {
    background-color: var(--ramonak-color);
    color: white;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
}
