* {
  box-sizing: border-box;
}

html {
    background: url(./assets/background-baw.png) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

body {
    font-family: Arial, Helvetica, sans-serif;
}

a {
    text-decoration: none;
    color: black;
}