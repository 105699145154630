@import "../../assets/scss/mixins.scss";

.parameters {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(to bottom left, black 60%, #e0314b);
  height: 85vh;
  width: 80vw;
  margin: auto;
  border-radius: 20px;

  @include breakpoint {
    overflow: auto;
    width: 100%;
    height: 100%;
  }

  .button-groups {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint {
      overflow: auto;
      height: 100%;
      width: 100%;
      padding-bottom: 10vh;
    }

    .icon-with-text {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .with-weights-text {
      color: white;
      margin-left: 20px;
    }
  }

  .title {
    color: white;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
}
