@import "../../assets/scss/mixins.scss";

.next-button-container {
  width: 100%;
  height: 10vh;
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 1s;

  @include breakpoint {
    width: 100%;
    position: fixed;
    bottom: 0;
    align-items: flex-end;

    a {
      width: 100%;
    }
  }

  .next-button {
    width: 12vw;
    background-color: var(--ramonak-color);
    color: white;
    font-size: 36px;
    text-transform: uppercase;
    font-weight: bolder;
    border: 1.5px solid var(--ramonak-color);
    border-radius: 10px;
    cursor: pointer;
    margin: 0 1vw 0 0;

    @include breakpoint {
      width: 100%;
      height: 100%;
      margin: auto 0 0 0;
    }
  }
}
