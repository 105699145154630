@import "../../assets/scss/mixins.scss";

.startpage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 80px;
  height: 90vh;

  @include breakpoint {
    padding: 20px;
  }
}
