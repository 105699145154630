@import "../../assets/scss/mixins.scss";

.button-group {
  display: flex;
  flex-direction: row;
  width: 80vw;

  @include breakpoint {
    flex-direction: column;
    align-items: center;
  }
}
