@import "../../assets/scss/mixins.scss";

.active-exercise-name {
  visibility: hidden;

  @include breakpoint {
    visibility: visible;
    font-size: xx-large;
    color: white;
    width: 100%;
    text-align: center;
  }
}
