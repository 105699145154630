.exercise-item {
  font-size: large;
  text-align: left;
  color: rgba(224, 49, 75, 0.5);
  padding-left: 20px;
  padding-top: 4vh;
  width: auto;

  &.active {
    color: var(--ramonak-color);
  }
}
