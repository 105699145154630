.next-exercise {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  // padding-top: 10%;

  &.visible {
    visibility: visible;
    text-align: center;
    font-size: 24px;
    color: white;
  }

  .name {
    margin: 10px;
  }
}
