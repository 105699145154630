@import "../../assets/scss/mixins.scss";

.circuits-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(to bottom left, black 60%, #e0314b);
  height: 85vh;
  width: 80vw;
  margin: auto;
  border-radius: 20px;

  @include breakpoint {
    width: 100%;
  }

  .title {
    color: white;
    text-transform: uppercase;
  }

  .button-group {
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 70vh;
    justify-content: space-evenly;
  }
}
