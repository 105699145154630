@import "../../assets/scss/mixins.scss";

.custom-button {
  min-width: 20vw;
  min-height: 10vh;
  letter-spacing: 0.5px;
  line-height: 10vh;
  background-color: var(--ramonak-color);
  color: white;
  font-size: 9vh;
  text-transform: uppercase;
  border: 1.5px solid var(--ramonak-color);
  border-radius: 10px;
  cursor: pointer;
  margin: 0 35px;

  @include breakpoint {
    width: 55vw;
    margin: 0;
    font-size: 5vh;
    letter-spacing: unset;
    line-height: unset;
  }

  &:hover {
    background-color: white;
    color: var(--ramonak-color);
  }

  &.google-sign-in {
    background-color: #4285f4;
    color: white;

    &:hover {
      background-color: #357ae8;
      border: none;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.65;
  }
}
