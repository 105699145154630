.landing-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .main-section {
    color: var(--ramonak-color);
    width: 100%;
    height: 100vh;

    .main-title {
      font-size: 80px;
      font-weight: 700;
      line-height: 85px;
    }

    .main-subtitle {
      font-size: 48px;
      font-weight: 700;
      line-height: 55px;
    }
  }

  .tabata-app-divider {
    width: 100%;
    font-size: xx-large;
    font-weight: bolder;
    color: var(--ramonak-color);
    text-align: left;
    padding: 50px;
  }
}
