.header {
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .logo-container {
    width: 70px;
    height: 100%;
    padding: 5px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .options {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;

    .option {
      padding: 10px 15px;
      cursor: pointer;
    }
  }
}
